<template>
  <center-screen background-color="secondary"
    style="background-image: url(/name-bg.svg); background-position: center center;"
  >
    <v-form ref="form" v-model="valid" @submit.prevent>
      <v-row no-gutters>
        <v-col cols="12" sm="4">
          <v-combobox
            v-model="selectedCountry"
            :items="countryCodes"
            label="Country"
            item-text="name"
            :rule="rules.countryDialCode"
            dense
            prepend-icon="mdi-phone"
          >
            <template v-slot:item="{ item }">
              <img
                :src="
                  `https://flagcdn.com/16x12/${item.code.toLowerCase()}.png`
                "
                width="16"
                height="12"
                :alt="item.name"
              />
              <span class="px-1">{{ item.name }}</span>
            </template>
          </v-combobox>
        </v-col>
        <v-col cols="12" sm="8">
          <v-text-field
            v-model="phone"
            :counter="9"
            :rules="rules.phone"
            :label="$t('general.phone')"
            required
            type="number"
            dense
          ></v-text-field>
        </v-col>
      </v-row>

      <!-- <v-row>
        <v-col>
          <v-text-field
            id="pin"
            name="pin"
            v-model="model.key"
            :label="$t('user.pinCode')"
            prepend-icon="mdi-lock"
            counter="4"
            @keyup="hitEnter"
            :rules="rules.pin"
            dense
          />
        </v-col>
      </v-row> -->

      <!-- <v-row>
        <v-col class="text-center">
          <v-btn
            color="secondary"
            class="text--primary"
            depressed
            :loading="sending"
            @click="requestPin"
          >
            <v-icon small>mdi-message-processing</v-icon>
            {{ $t("login.resendPin") }}
          </v-btn>
        </v-col>
      </v-row> -->
    </v-form>

    <template v-slot:actions>
      <v-btn text color="primary" tile depressed @click="goToRegister">
        {{ $t("login.register") }}
      </v-btn>
      <v-divider vertical />
      <v-btn text color="primary" tile depressed @click="goToLoginByEmail">
        {{ $t("login.loginByEmail") }}
      </v-btn>
      <v-spacer />
      <v-btn
        color="primary"
        tile
        depressed
        @click="requestPin"
        :loading="sending"
      >
        {{ $t("general.next") }}
      </v-btn>
    </template>
  </center-screen>
</template>

<script>
import { mapActions } from "vuex";
import Copyright from "@/common/components/Copyright";
import CenterScreen from "@/common/layouts/CenterScreen";

export default {
  name: "Login",
  components: { Copyright, CenterScreen },
  data() {
    return {
      valid: false,
      loading: false,
      sending: false,
      redirect: "/",
      phone: null,
      selectedCountry: null,
      countryCodes: [
        {
          name: "Jordan",
          nameAr: "الأردن",
          dialCode: "+962",
          code: "JO",
        },
        {
          name: "Yemen",
          nameAr: "اليمن",
          dialCode: "+967",
          code: "YE",
        },
      ],
      rules: {
        countryDialCode: [
          (v) =>
            !!v || "Pease select the country dia-code for the phone number",
        ],
        phone: [
          (v) => !!v || "Phone Number is required",
          (v) => {
            const re = /^\d{9}$/gi;
            return (
              (!!v && re.test(String(v))) ||
              "Wrong phone number pattern (e.g. 7123456789)"
            );
          },
        ],
        // pin: [
        //   (v) => !!v || "This field is required",
        //   (v) =>
        //     (!!v && v.length === 4) ||
        //     "Please enter the pin code we sent to your number",
        //   (v) => {
        //     const re = /^[0-9]{4}$/;
        //     return (
        //       re.test(String(v).toLowerCase()) || "PIN-Code should be 4 digits"
        //     );
        //   },
        // ],
      },
    };
  },
  methods: {
    ...mapActions("auth", ["pinLogin", "requestLoginPin"]),

    hitEnter(event) {
      if (event.keyCode === 13) {
        event.preventDefault();
        this.tryLogin();
      }
    },
    requestPin() {
      this.$refs.form.validate();

      setTimeout(() => {
        if(this.valid) {
          this.sending = true;
          const phone = this.selectedCountry.dialCode + this.phone.trim();
          this.requestLoginPin(phone).then(() => {
            this.sending = false;
            window.localStorage.setItem("loginPhone", phone);
            this.$router.push({ name: "pin", query: { phone, isLogin: true } });
          }).catch(() => this.sending = false);
        }
      }, 300);
    },
    // tryLogin() {
    //   this.$refs.form.validate();

    //   setTimeout(() => {
    //     if (this.valid) {
    //       this.loading = true;
    //       const phone = this.selectedCountry.dialCode + this.phone.trim();
    //       this.pinLogin({ type:"pin.login", principal: phone, credential: this.model.key })
    //         .then(() => {
    //           this.localStorage.setItem("loginPhone", phone);
    //           this.$router.push({ name: "pin", params: { phone, isLogin: true } });
    //           this.loading = false;
    //         })
    //         .catch(() => (this.loading = false));
    //     }
    //   }, 300);
    // },
    goToRegister() {
      this.$router.push({ name: "register" });
    },
    goToLoginByEmail() {
      this.$router.push({ name: "alt-login" });
    },
  },
};
</script>
